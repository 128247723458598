.dropdown-menu {
    left: auto !important;
    right: 0;
}

.navbar-toggler:focus {
    box-shadow: none !important;
    outline: none !important;
}

.dropdown-item:hover {
    background-color: var(--bs-purple) !important;
    color: #222;
}