.btn-primary {
	background-color: var(--bs-purple);
    border: none;
    border-radius: 0.5em;
    font-size: 1.10rem;
    height: auto;
    padding: 0.5rem 1rem;
}

.btn-primary:hover, .btn-primary:active  {
	background-color: var(--bs-purple-secondary) !important;
    border: none;
}

.public-title {
    color: #6f5588;
    font-weight: 350;
}

.public-title-secondary {
    color: #00377a;
    font-weight: 300;
}

p {
    color: rgba(0,0,0,0.7);
}

.big-div {
    background-color: #fff !important;
}

.bg-purple-light {
	background-color: rgba(188,181,233,.5);
}

.bg-blue-vlight {
	background-color: rgb(105,155,216,.25);
}