.EditProfile> ::-webkit-scrollbar {
    width: 5px;
}

.EditProfile> ::-webkit-scrollbar-button {
    display: none !important;
}

.EditProfile> ::-webkit-scrollbar-thumb {
    background-color: white !important;
    border: 1px solid black !important;
    border-radius: 100px;
}

.EditProfile> ::-webkit-scrollbar-track {
    background-color: transparent !important;
}