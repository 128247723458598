.Loader {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 84px;
    height: 84px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}