.edit-btn:hover {
    border-radius: 15px;
    background-color: rgba(188, 181, 233, .7);
    
}

.edit-btn {
    color: rgba(0, 0, 0, 0.7);
}

.profile-title {
    color: rgba(0, 0, 0, 0.7);
    word-break: break-word;
}

.profile-bg {
    background-color: rgb(245, 245, 245);
}

.social-link:hover {
    opacity: 1;
    color: #9858d4;
}

.AddProject .row > div:not(.my-2) {
    display:none;
}
