.SmallLoader {
    /* z-index: 420; */
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px 7px;
}

.lds-ring-small {
    display: inline-block;
    position: relative;
    width: 15px;
    height: 15px;
}

.lds-ring-small div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    border: 2px solid #0075FF;
    border-radius: 50%;
    animation: lds-ring-small 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0075FF transparent transparent transparent;
}

.lds-ring-small div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring-small div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring-small div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring-small {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}