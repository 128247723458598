.form-control:focus {
    outline: none !important;
    box-shadow: none !important;
}

.btn-primary {
    background-color: rgba(111,85,184, .8);
    border: none;
    border-radius: 15px;
    height: 3rem;
    font-size: 18px;
    color: white;
}

.btn-forgot:hover {
    text-decoration: underline;
}

.btn-signup:hover {
    text-decoration: underline;
}
