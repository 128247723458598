.form-control:focus {
    outline: none !important;
    box-shadow: none !important;
    border-bottom-color: #6658d3;
}

.sign-up-form {
    border: 0;
    z-index: 1;
    background-color: transparent;
    border-bottom: 2px solid #eee;
    font: inherit;
    font-size: 1.125rem;
    padding: .25rem 0;
}

.btn-primary {
    background-color: rgba(111,85,184, .8);
    border: none;
    border-radius: 15px;
    height: 3rem;
    font-size: 18px;
}