.AddProject> ::-webkit-scrollbar {
    width: 5px;
}

.AddProject> ::-webkit-scrollbar-button {
    display: none !important;
}

.AddProject> ::-webkit-scrollbar-thumb {
    background-color: white !important;
    border: 1px solid black !important;
    border-radius: 100px;
}

.AddProject> ::-webkit-scrollbar-track {
    background-color: transparent !important;
}

.edit-img-hvr:hover {
    opacity: 0.5;
    cursor: pointer;
}

.edit-img-middle {
    transition: .5s ease;
    width: 100%;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 155px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.edit-img-middle:hover {
    opacity: 1;
}

.edit-img-text {
    background-color: #04AA6D;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
}