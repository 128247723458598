:root {
    --bs-purple: #bcb5e9;
    --bs-purple-secondary: #6f5588;
}

.btn-primary {
	background-color: var(--bs-purple);
    border: none;
    border-radius: 0.5em;
    font-size: 1.10rem;
    height: auto;
    padding: 0.5rem 1rem;
}
.btn-primary:hover, .btn-primary:active  {
	background-color: var(--bs-purple-secondary);
    border: none;
}